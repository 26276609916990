<template>
  <div class="page">
    <h1>404</h1>
    <h3>页面不存在</h3>
    <van-button type="success" size="small" plain @click="toIndex">返回首页</van-button>
  </div>
</template>

<script>
export default {
  name: "404",
  methods: {
    toIndex() {
      this.$router.push({
        path: '/'
      })
    }
  }
}
</script>

<style scoped>
.page {
  background: #ffffff;
  text-align: center;
}
</style>
